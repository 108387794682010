import '../styles/header.css';

function Header() {
  return (

    <a id="logo" href="rolando.us" className="header-component">
      <svg id="logo-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path id="logo-icon-3" className="logo-icon-part logo-icon-3" d="M16.09,15.94C2.75,9.15,3.57,5.82,4.65,4.72A15.9,15.9,0,0,0,.22,13.31c0,.11,0,.23-.06.35s-.07.45-.09.69h0c0,.25,0,.5-.06.76v.09a1.62,1.62,0,0,0,0,.22C0,17.24.5,18,1.24,18.5h0a2.67,2.67,0,0,0,2.27.25,2.13,2.13,0,0,0,1.1-.93h0A4,4,0,0,1,12,20v8.4C12,30.1,13,32,16.09,32a16,16,0,0,0,11.27-4.74c.88-.9,2.21-4.47-11.27-11.29" />
        <path id="logo-icon-2" className="logo-icon-part logo-icon-2" d="M32,16A16,16,0,0,0,16,0,15.82,15.82,0,0,0,8.82,1.7a16,16,0,0,0-4.17,3c-1.08,1.1-2,4.43,14.44,.22S28.24,26.33,27.36,27.23A15.91,15.91,0,0,0,32,16" />
        <path id="logo-icon-4" className="logo-icon-part logo-icon-4" d="M14,26.84c-.7,0-1.45,0-2-.07v1.61C12,30.1,13,32,16.1,32a16,16,0,0,0,7.23-1.78c2.25-1.4-1.56-3.28-9.36-3.35" />
        <path id="logo-icon-1" className="logo-icon-part logo-icon-1" d="M18.05,5.13C26.3,5.2,29.14,7.46,31,10.34A16,16,0,0,0,8.82,1.7C6.3,3.11,10.11,5.06,18.05,5.13" />
      </svg>
      <span className="logo-text">Rolando García</span>
    </a>

  );
}

export default Header;
