import { useState, useEffect, useRef } from 'react';
import '../styles/section.css';
import greenImage from '../pics/green.png';
import greenbrownImage from '../pics/greenbrown.png';
import greenyellowImage from '../pics/greenyellow.png';

function Section() {
  const words = ['javascript', 'java', 'docker', 'sql', 'react', 'git'];
  const [wordIndex, setWordIndex] = useState(0);
  const animationRef = useRef(null);
  const doAnimationEnd = () => {
    setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
  };

  const [showParagraph, setShowParagraph] = useState(false);
  const flipcard1Ref = useRef(null);

  useEffect(() => {
    const flipcard1Element = flipcard1Ref.current;
    const handleMouseEnter = () => {
      setShowParagraph(true);
    };
    const handleMouseLeave = () => {
      setShowParagraph(false);
    };
    flipcard1Element.addEventListener('mouseenter', handleMouseEnter);
    flipcard1Element.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      flipcard1Element.removeEventListener('mouseenter', handleMouseEnter);
      flipcard1Element.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  // paragraph 2
  const [showParagraph2, setShowParagraph2] = useState(false);
  const flipcard2Ref = useRef(null);

  useEffect(() => {
    const flipcard2Element = flipcard2Ref.current;
    const handleMouseEnter = () => {
      setShowParagraph2(true);
    };
    const handleMouseLeave = () => {
      setShowParagraph2(false);
    };
    flipcard2Element.addEventListener('mouseenter', handleMouseEnter);
    flipcard2Element.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      flipcard2Element.removeEventListener('mouseenter', handleMouseEnter);
      flipcard2Element.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  // paragraph 3
  const [showParagraph3, setShowParagraph3] = useState(false);
  const flipcard3Ref = useRef(null);

  useEffect(() => {
    const flipcard3Element = flipcard3Ref.current;
    const handleMouseEnter = () => {
      setShowParagraph3(true);
    };
    const handleMouseLeave = () => {
      setShowParagraph3(false);
    };
    flipcard3Element.addEventListener('mouseenter', handleMouseEnter);
    flipcard3Element.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      flipcard3Element.removeEventListener('mouseenter', handleMouseEnter);
      flipcard3Element.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    };
    animationRef.current.addEventListener('animationEnd', handleAnimationEnd);
    const blah = animationRef.current;
    return () => blah.removeEventListener('animationEnd', handleAnimationEnd);
  }, [wordIndex, words.length]);

  return (
    <section className="page-block home-part" id="intro">
      <div className="container part-container">
        <div className="part-text">
          <h1 className="col-sm-offset-1 col-sm-10 part-title">
            Developer with experience
            {' '}
            <span className="title-second-line" ref={animationRef} key={wordIndex} onAnimationEnd={doAnimationEnd}>
              in
              {' '}
              <span className="title-word">{words[wordIndex]}</span>
            </span>
          </h1>
        </div>
        <div className="part-images">
          <div className="part-images-inner part-images-inner-3-images">
            <picture className="col-sm-3 part-image part-image-1 flipcard1 part-image-container" ref={flipcard1Ref}>
              <div className={`flipcard1-paragraph ${showParagraph ? 'show' : ''}`}>
                <h2>Bio</h2>
                <br />
                <span>Hi, </span>
                <br />
                <br />
                <span>My name is Rolando, and I&apos;m launching my career as a developer</span>
                <span> seeking to understand the complexities of modern computer systems.</span>
                <br />
                <br />
                <span>I have experience as a QA tester of a data intensive web application. </span>
                <span>I find the challenge I&apos;m looking for my career in the nuances of </span>
                <span>distributed systems. Creating something that is useful is </span>
                <span>my primary professional goal.</span>
                <br />
                <br />
                <span>I have a BA in Mathematics from The University of Texas at Austin. </span>
                <span>I&apos;m self-motivated, coachable, naturally curious, and </span>
                <span>continually working on improving my chops. </span>
              </div>
              <img className="img-responsive part-img part-image-1" src={greenImage} alt="Packaging" />
            </picture>
            <picture className="col-sm-3 part-image part-image-2 flipcard2 part-image-container" ref={flipcard2Ref}>
              <div className={`flipcard2-paragraph ${showParagraph2 ? 'show' : ''}`}>
                <h2>Projects</h2>
                <br />
                <span>I like to work on data visualization projects and embedded system</span>
                <span> projects.</span>
                <br />
                <br />
                <span>Data visualization is a tool with pragmatic use </span>
                <span> but can also be design-oriented. </span>
                <span>A favorite source source of inspiration of mine is the book </span>
                <span>&apos;Ten global trends every smart person should know.&apos; I&apos;m </span>
                <span> currently also learning embedded systems to learn c++ and make</span>
                <span> gadgets for my home, while also making them aestheticly pleasing.</span>
              </div>
              <img className="img-responsive part-image-2" src={greenbrownImage} alt="Interior" />
            </picture>
            <picture className="col-sm-3 part-image part-image-3 flipcard3 part-image-container" ref={flipcard3Ref}>
              <div className={`flipcard3-paragraph ${showParagraph3 ? 'show' : ''}`}>
                <h2>Interests</h2>
                <br />
                <span>In my free time I like to play beach volleyball, and go</span>
                <span> one a walk with my wife and our dog.</span>
                <br />
                <br />
                <span>I like studying history, languages and the odd self-help book.</span>
                <span> Some of my favorite tv shows are the Office, Better Call</span>
                <span> Saul, and Survivor.</span>
              </div>
              <img className="img-responsive part-image-3" src={greenyellowImage} alt="Book" />
            </picture>
          </div>
        </div>
      </div>

    </section>

  );
}

export default Section;
