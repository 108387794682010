import './App.css';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import Section from './components/Section';

function App() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      const windowScroll = (window.scrollY / window.innerHeight) * 100 > 100;
      setOffset(window.scrollY);

      if (windowScroll) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    };

    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const percentOfScreenHeightScrolled = (offset / window.innerHeight) * 100;
  document.documentElement.style.setProperty('--scroll', percentOfScreenHeightScrolled);
  return (

    <>
      <Header />
      <div id="main-container">
        <div id="page-container-home" className="page-container">
          <Section />
        </div>

        <section className="page-block home-part" id="find-palettes">
          <div className="container part-container">
            <div className="part-text">
              <h2 className="col-sm-offset-1 col-sm-10 part-title">
                Based in
                <span className="title-word"> Austin, TX</span>
              </h2>
              <p className="col-sm-offset-2 col-sm-8">looking to gain experience building data-intensive websites</p>
            </div>
          </div>
        </section>

        <section className="page-block home-part" id="preview" data-node-type="preview-block" data-index="2">
          <div className="container part-container">
            <div className="part-images">
              <div className="part-images-inner part-images-inner-1-image">
                <div className="col-sm-3 part-image part-image-with-video part-image-1">
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-block home-part" id="moodboards" data-node-type="home-part" data-index="5">
          <div className="container part-container">
            <div className="part-text">
              <h2 className="col-sm-offset-1 col-sm-10 part-title">
                Get in touch
                <span className="title-word"> with me</span>
                {' '}
                now!
              </h2>
              <p className="col-sm-offset-1 col-sm-10">
                Please checkout my
                {' '}
                <a href="https://github.com/rologos" target="_blank" rel="noreferrer">GitHub</a>
              </p>
            </div>
          </div>
        </section>

      </div>
    </>

  );
}

export default App;
